<template>
	<v-card>
		<v-toolbar dense color="primary">
			<v-toolbar-title class="white--text"
				>Detalles incidencia Nº {{ idIncidencia }}</v-toolbar-title
			>
			<v-spacer></v-spacer>
			<v-btn color="white" icon @click="$emit('close')">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-toolbar>
		<v-container fluid>
			<v-row>
				<v-col cols="12" md="6" style="position: relative">
					<div class="sticky py-4">
						<div class="d-flex">
							<v-dialog max-width="1400px" width="100%">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										class="mx-auto"
										color="primary"
										outlined
										v-on="on"
										v-bind="attrs"
									>
										Ver el contrato
									</v-btn>
								</template>
								<DetallesContrato
									:_codigoContrato="codigoContrato"
									:_idContrato="idContrato"
									class="pa-0"
								/>
							</v-dialog>
						</div>
						<text-editor class="my-6" v-model="msg" />

						<div class="d-flex">
							<v-btn
								v-if="
									$root.acceso('CERRAR_INCIDENCIA') &&
									data.estado == 'Abierta'
								"
								outlined
								color="error"
								@click="cerrar"
								:disabled="data.estado == 'Cerrada'"
							>
								<v-icon left>mdi-close</v-icon>
								Cerrar
							</v-btn>
							<v-spacer />
							<v-btn
								outlined
								color="primary"
								@click="send"
								:disabled="data.estado == 'Cerrada' || msg.length == 0"
							>
								enviar
								<v-icon right>mdi-send</v-icon>
							</v-btn>
						</div>
						<div class="d-flex mt-4">
							<v-alert
								type="info"
								dense
								text
								v-if="!($root.acceso('CERRAR_INCIDENCIA'))
								"
								>Tu mensaje cerrará la incidencia</v-alert
							>
						</div>
					</div>
				</v-col>
				<v-col cols="12" md="6" style="height: 100%; overflow-y: auto">
					<v-btn small @click="getMessages" color="primary">
						<v-icon small left>mdi-reload</v-icon>
						Recargar
					</v-btn>
					<v-timeline dense>
						<v-timeline-item>
							<template v-slot:icon>
								<v-avatar color="success" size="40" />
							</template>
							<div class="d-flex">
								<v-chip outlined label color="success">
									<v-icon left>mdi-check</v-icon>
									Abierta
								</v-chip>
							</div>
						</v-timeline-item>

						<v-slide-x-transition group>
							<v-timeline-item v-for="(message, i) in messages" :key="i" small>
								<v-card>
									<v-card-subtitle class="py-2">
										{{ message.Nombre }}
										<v-icon
											small
											color="primary"
											v-if="
												JSON.parse(
													decode64($store.getters.getJwtEmpresa.split('.')[1])
												).idUsuario == message.idUsuario
											"
											>mdi-account</v-icon
										>
										-
										{{
											new Date(Number(message.fecha) * 1000).toLocaleString()
										}}
									</v-card-subtitle>
									<div class="px-4 py-1" v-html="message.mensaje" />
								</v-card>
							</v-timeline-item>
						</v-slide-x-transition>

						<v-timeline-item v-if="data.estado == 'Cerrada'">
							<template v-slot:icon>
								<v-avatar color="error" size="40" />
							</template>
							<div class="d-flex">
								<v-chip class="" outlined label color="error">
									<v-icon left>mdi-close</v-icon>
									Cerrada
								</v-chip>
							</div>
						</v-timeline-item>
					</v-timeline>
					<v-btn small @click="getMessages" color="primary">
						<v-icon small left>mdi-reload</v-icon>
						Recargar
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
import { parseDate, decode64 } from "@/utils/index.js";

export default {
	components: {
		TextEditor: () => import("@/components/TextEditor.vue"),
		DetallesContrato: () => import("@/modules/contratos/views/DetallesContrato.vue"),
	},
	props: {
		idIncidencia: String,
		codigoContrato: String | Number,
		idContrato: String | Number,
	},
	data() {
		return {
			msg: "",
			messages: [],
			data: {},
			estado: "",
			interval: null,
		};
	},
	methods: {
		parseDate,
		decode64,
		getMessages() {
			axios({
				method: "GET",
				url: `${process.env.VUE_APP_OUR_API_URL}/mensajesincidencias.php`,
				params: {
					token: this.$store.getters.getJwtEmpresa,
					idIncidencia: this.idIncidencia,
				},
			})
				.then(({ data }) => {
					this.messages = data;
				})
				.catch((err) => {
					console.error(err);
				});
		},
		cerrar() {
			axios({
				method: "POST",
				url: `${process.env.VUE_APP_OUR_API_URL}/incidencias.php`,
				data: {
					token: this.$store.getters.getJwtEmpresa,
					idIncidencia: this.idIncidencia,
					estado: "Cerrada",
					estadoContrato: "Pendiente Revisión",
				},
			})
				.then(({ data }) => {
					this.$root.$emit("snack", `Contrato cambiado a Pendiente Revisión`);
					this.$emit("reload");
					this.$emit("close");
					this.getMessages();
				})
				.catch((err) => console.error(err));
		},
		send() {
			axios({
				method: "POST",
				url: `${process.env.VUE_APP_OUR_API_URL}/mensajesincidencias.php`,
				data: {
					token: this.$store.getters.getJwtEmpresa,
					idIncidencia: this.idIncidencia,
					mensaje: this.msg,
					estado: "Abierta",
				},
			})
				.then(({ data }) => {
					if (this.data.estado != this.estado) {
						this.data.estado = this.estado;
						this.$emit("reload");
					}
					this.msg = "";
					this.getMessages();

					if (
						this.data.idUsuario ==
							this.$store.getters.getDecodedJwtEmpresa.idUsuario &&
						!$root.acceso('CERRAR_INCIDENCIAS')
					)
						return;
					else this.cerrar();
				})
				.catch((err) => console.error(err));
		},
	},
	mounted() {
		let { IdAgente } = this.$store.getters.getDatosEmpresa.iss;
		axios({
			method: "GET",
			url: `${process.env.VUE_APP_OUR_API_URL}/incidencias.php`,
			params: {
				token: this.$store.getters.getJwtEmpresa,
				idIncidencia: String(this.idIncidencia),
				idAgente: IdAgente,
			},
		}).then(({ data }) => {
			if (data == false) {
				this.$root.$emit("snack", "No tienes permiso para ver esta incidencia");
				this.$emit("close");
			}
			this.data = data;
			this.estado = data.estado;
		});
		this.getMessages();

		this.interval = setInterval(() => {
			this.getMessages();
		}, 30 * 1000);
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
};
</script>

<style>
.sticky {
	position: sticky;
	top: 0;
}
</style>