var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"dense":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Detalles incidencia Nº "+_vm._s(_vm.idIncidencia))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticStyle:{"position":"relative"},attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"sticky py-4"},[_c('div',{staticClass:"d-flex"},[_c('v-dialog',{attrs:{"max-width":"1400px","width":"100%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-auto",attrs:{"color":"primary","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" Ver el contrato ")])]}}])},[_c('DetallesContrato',{staticClass:"pa-0",attrs:{"_codigoContrato":_vm.codigoContrato,"_idContrato":_vm.idContrato}})],1)],1),_c('text-editor',{staticClass:"my-6",model:{value:(_vm.msg),callback:function ($$v) {_vm.msg=$$v},expression:"msg"}}),_c('div',{staticClass:"d-flex"},[(
								_vm.$root.acceso('CERRAR_INCIDENCIA') &&
								_vm.data.estado == 'Abierta'
							)?_c('v-btn',{attrs:{"outlined":"","color":"error","disabled":_vm.data.estado == 'Cerrada'},on:{"click":_vm.cerrar}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(" Cerrar ")],1):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary","disabled":_vm.data.estado == 'Cerrada' || _vm.msg.length == 0},on:{"click":_vm.send}},[_vm._v(" enviar "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-send")])],1)],1),_c('div',{staticClass:"d-flex mt-4"},[(!(_vm.$root.acceso('CERRAR_INCIDENCIA'))
							)?_c('v-alert',{attrs:{"type":"info","dense":"","text":""}},[_vm._v("Tu mensaje cerrará la incidencia")]):_vm._e()],1)],1)]),_c('v-col',{staticStyle:{"height":"100%","overflow-y":"auto"},attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.getMessages}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-reload")]),_vm._v(" Recargar ")],1),_c('v-timeline',{attrs:{"dense":""}},[_c('v-timeline-item',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-avatar',{attrs:{"color":"success","size":"40"}})]},proxy:true}])},[_c('div',{staticClass:"d-flex"},[_c('v-chip',{attrs:{"outlined":"","label":"","color":"success"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" Abierta ")],1)],1)]),_c('v-slide-x-transition',{attrs:{"group":""}},_vm._l((_vm.messages),function(message,i){return _c('v-timeline-item',{key:i,attrs:{"small":""}},[_c('v-card',[_c('v-card-subtitle',{staticClass:"py-2"},[_vm._v(" "+_vm._s(message.Nombre)+" "),(
											JSON.parse(
												_vm.decode64(_vm.$store.getters.getJwtEmpresa.split('.')[1])
											).idUsuario == message.idUsuario
										)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-account")]):_vm._e(),_vm._v(" - "+_vm._s(new Date(Number(message.fecha) * 1000).toLocaleString())+" ")],1),_c('div',{staticClass:"px-4 py-1",domProps:{"innerHTML":_vm._s(message.mensaje)}})],1)],1)}),1),(_vm.data.estado == 'Cerrada')?_c('v-timeline-item',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-avatar',{attrs:{"color":"error","size":"40"}})]},proxy:true}],null,false,2924498807)},[_c('div',{staticClass:"d-flex"},[_c('v-chip',{attrs:{"outlined":"","label":"","color":"error"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(" Cerrada ")],1)],1)]):_vm._e()],1),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.getMessages}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-reload")]),_vm._v(" Recargar ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }